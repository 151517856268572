import axios from 'axios';
import store from '../store/store';
import { readTransitJs } from './transitJsUtils';
import { getLanguage } from '../i18n/i18nUtil';
const genericErrorMessage = 'An error has occurred.';
const httpRequestHandler = async (requestInfo, requestParam) => {
  const { REACT_APP_API_URL } = process.env;

  let requestConfig = {
    url: requestInfo.url ? requestInfo.url : '',
    method: requestInfo.method ? requestInfo.method : 'GET',
    transformResponse: requestInfo.transformResponse
      ? requestInfo.transformResponse
      : [],
    headers: {
      Accept: requestInfo.acceptHeader ? requestInfo.acceptHeader : '*/*',
      'Content-Type': requestInfo.contentTypeHeader
        ? requestInfo.contentTypeHeader
        : 'application/json',
      'accept-language': `${getLanguage()}`,
    },
    data: requestParam ? JSON.stringify(requestParam) : '',
  };
  const registrationQuantityUnits = requestInfo['x-registration-unit'];
  if (registrationQuantityUnits) {
    requestConfig.headers['x-registration-unit'] = registrationQuantityUnits;
  }

  if (requestInfo.hasAuth) {
    const authContext = store.getState().authContext;
    requestConfig.headers = {
      ...requestConfig.headers,
      'X-Auth-Token': authContext.authToken,
    };
  }

  if (requestParam) {
    switch (requestInfo.requestParamType) {
      case 'json':
        requestConfig.data = JSON.stringify(requestParam);
        break;
      case 'transit-json':
        requestConfig.data = requestParam;
        break;
      case 'query-string':
        requestConfig.params = requestParam;
        break;
      default:
        requestConfig.data = JSON.stringify(requestParam);
    }
  }

  try {
    axios.defaults.baseURL = REACT_APP_API_URL;
    const response = await axios(requestConfig);

    let apiResponse = {
      status: response.status,
      result: 1,
      errorMessage: '',
      data: response ? processResponseData(response) : '',
      resourceLastModifiedDate: response?.headers['last-modified']
        ? response?.headers['last-modified']
        : undefined,
    };

    return apiResponse;
  } catch (error) {
    let apiResponse = {
      result: 0,
      errorMessage: processErrorMessage(error),
      data: null,
      statusCode: error?.response?.status,
    };

    return apiResponse;
  }
};

const processResponseData = (response) => {
  let responseData = '';

  if (response.data) {
    if (response.headers['content-type'] === 'application/transit+json') {
      responseData = readTransitJs(response.data);
    } else {
      responseData = response.data;
    }
  }

  return responseData;
};

const processErrorMessage = (error) => {
  let errorMessage = '';
  if (error.response) {
    errorMessage = error.response.data;

    if (error.response.headers['content-type'] === 'application/transit+json') {
      errorMessage = error.response.data
        ? readTransitJs(error.response.data)
        : genericErrorMessage;
    }
  } else {
    errorMessage = genericErrorMessage;
  }
  return errorMessage;
};

export default httpRequestHandler;
