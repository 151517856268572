import React from 'react';
import './i18n/i18n.js';
import { BrowserRouter } from 'react-router-dom';
import RoutingConfig from './routing/RoutingConfig';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utilities/themeProvider';
import './assets/styles/main.scss';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <RoutingConfig />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
