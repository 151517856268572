import {
  createTransitData,
  getTransitData,
  getTransitDataCollection,
  mapTransitArrayMapToObject,
  mapObjectToTransitArrayMap,
} from '../../../utilities/transitJsUtils';
import httpRequestHandler from '.././../../utilities/httpRequestHandler';
import {
  setProjects,
  deleteProjectTask,
  setTimesheetTime,
} from '../../../store/projectContextSlice';
import {
  setRegistration,
  addRegistration,
  editRegistration,
  deleteRegistration,
  bulkEditRegistration,
} from '../../../store/registrationContextSlice';
import store from '../../../store/store';

export const getRegistrations = (regObj) => {
  return async (dispatch) => {
    try {
      let registrationsData = await httpRequestHandler(
        getRegistrationsInfo,
        regObj,
      );
      let mappedData = projectsDataMapper(registrationsData.data);
      dispatch(setProjects({ projects: mappedData.projects }));
      dispatch(setRegistration({ registrations: mappedData.registrations }));
      dispatch(setTimesheetTime(regObj));
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

const projectsDataMapper = (data) => {
  let rawData = Object.assign([], data);
  let mappedProjectsList = [];
  let mappedRegistrationsList = [];
  let mapProjectToObject = {
    projectId: 'db/id',
    projectName: 'project/name',
    projectOwnerId: 'project/owner',
    projectOrganizationId: 'project/organization',
    projectTasks: 'project/tasks',
    projectStatus: 'project/status',
  };

  let mapTaskToObject = {
    taskId: 'db/id',
    taskProjectId: 'task/project',
    taskTitle: 'task/title',
    taskRegistrations: 'meta/my-time',
    taskTotalRegTime: 'meta/total-time',
    taskCreator: 'meta/created-by',
    taskHasReg: 'meta/has-registration',
  };

  let mapRegistrationToObject = {
    regId: 'db/id',
    regDesc: 'registration/description',
    regStatus: 'registration/status',
    regDuration: 'registration/quantity',
    regCreatedBy: 'meta/created-at',
    regUpdatedBy: 'meta/updated-at',
  };

  rawData.forEach((project) => {
    let mappedProject = {};
    mappedProject = getTransitDataCollection(project, mapProjectToObject);
    //Note: when an organization id is null it means that it is a personal project so we need to assign
    //owner id which is the user id as the value.
    var ownerDetails = getOwnerDetails(mappedProject.projectOwnerId);
    if (mappedProject.projectOrganizationId == null) {
      mappedProject.projectOrganizationId = ownerDetails.id;
    } else {
      mappedProject.ownerId = ownerDetails.id;
    }

    let mappedTaskWithReg = [];
    mappedProject.projectTasks = mappedProject.projectTasks.map((task) => {
      let mappedTask = getTransitDataCollection(task, mapTaskToObject);

      let mappedReg = mapTransitArrayMapToObject(mappedTask.taskRegistrations);
      for (const [date, value] of Object.entries(mappedReg)) {
        let userReg = [];
        value.forEach((reg) => {
          reg = getTransitDataCollection(reg, mapRegistrationToObject);
          reg.regCreatedBy = new Date(reg.regCreatedBy).toUTCString();
          reg.regUpdatedBy = reg.regUpdatedBy
            ? new Date(reg.regUpdatedBy).toUTCString()
            : null;
          userReg.push(reg);
        });
        mappedReg[date] = userReg;
      }

      mappedTask.taskRegistrations = mappedReg;

      //add to list if task has a registration
      if (Object.keys(mappedTask.taskRegistrations).length > 0) {
        mappedTaskWithReg.push(mappedTask);
      }

      return mappedTask;
    });
    // mappedProject.projectOwnerId = ownerDetails;
    mappedProjectsList.push(mappedProject); //list of projects and tasks for the user

    if (mappedTaskWithReg.length > 0) {
      let copyMappedProject = Object.assign([], mappedProject);
      let taskPlaceHolder = {
        taskId: -1,
        taskProjectId: copyMappedProject.projectId,
        taskTitle: '',
        taskRegistrations: {},
        taskTotalRegTime: 0,
      };
      mappedTaskWithReg.push(taskPlaceHolder);
      copyMappedProject.projectTasks = mappedTaskWithReg;
      mappedRegistrationsList.push(copyMappedProject); //list of project and task with registration
    }
  });

  //NOTE: Commented out to have a reference if there's going to be an enhancement to have default projects on timesheet per org
  //Select distinct organization id of projects
  // const organizationsWithProj = [
  //   ...new Set(mappedProjectsList.map((proj) => proj.projectOrganizationId)),
  // ];

  //Check if an organization has registrations and if there are none, then assign default projects to it
  // organizationsWithProj.forEach((org) => {
  //   let orgHasRegistration =
  //     mappedRegistrationsList.filter(
  //       (proj) => proj.projectOrganizationId == org
  //     ).length > 0;

  //   if (!orgHasRegistration) {
  //     let defaultProjects = Object.assign(
  //       [],
  //       mappedProjectsList
  //         .filter((proj) => proj.projectOrganizationId == org)
  //         .slice(0, 3)
  //     );

  //     defaultProjects.forEach((project) => {
  //       let copyProj = Object.assign({}, project);
  //       let taskPlaceHolder = {
  //         taskId: -1,
  //         taskProjectId: project.projectId,
  //         taskTitle: "",
  //         taskRegistrations: {},
  //         taskTotalRegTime: 0,
  //       };
  //       copyProj.projectTasks = [];
  //       copyProj.projectTasks.push(taskPlaceHolder);
  //       mappedRegistrationsList.push(copyProj);
  //     });
  //   }
  // });
  return {
    projects: mappedProjectsList,
    registrations: mappedRegistrationsList,
  };
};

export const getListofProject = (regObj) => {
  return async (dispatch) => {
    try {
      let registrationsData = await httpRequestHandler(
        getRegistrationsInfo,
        regObj,
      );
      let mappedData = projecstMapper(registrationsData.data);
      dispatch(setProjects({ projects: mappedData.projects }));
      return mappedData.projects;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

const projecstMapper = (data) => {
  let rawData = Object.assign([], data);
  let mappedProjectsList = [];

  let mapProjectToObject = {
    projectId: 'db/id',
    projectName: 'project/name',
    projectOwnerId: 'project/owner',
    projectOrganizationId: 'project/organization',
    projectTasks: 'project/tasks',
    projectStatus: 'project/status',
  };
  rawData.forEach((project) => {
    let mappedProject = {};
    mappedProject = getTransitDataCollection(project, mapProjectToObject);
    var ownerDetails = getOwnerDetails(mappedProject.projectOwnerId);
    if (mappedProject.projectOrganizationId == null) {
      mappedProject.projectOrganizationId = ownerDetails.id;
    }
    mappedProjectsList.push(mappedProject);
  });

  return {
    projects: mappedProjectsList,
  };
};

export const addRegistrationService = (addRegObj, empId) => {
  return async (dispatch) => {
    try {
      const userContext = store.getState().userContext;
      let requestParamObj = {
        'registration/owner': { value: userContext.userId, isKeyword: false },
        'registration/description': {
          value: addRegObj.taskReg.regDesc,
          isKeyword: false,
        },
        'registration/task': { value: addRegObj.taskId, isKeyword: false },
        'registration/status': {
          value: 'registration.status/'.concat(addRegObj.taskReg.regStatus),
          isKeyword: true,
        },
        'registration/start': {
          value: new Date(Date.parse(addRegObj.regDate)),
          isKeyword: false,
        },
        'registration/start-offset': {
          value: new Date(addRegObj.regDate).getTimezoneOffset(),
          isKeyword: false,
        },
        'registration/quantity': {
          value: Number(addRegObj.taskReg.regDuration),
          isKeyword: false,
        },
        employment: {
          value: empId,
          isKeyword: false,
        },
      };

      let mapObjToTransit = createTransitData(requestParamObj);
      let registrationsData = await httpRequestHandler(
        addRegistrationsInfo,
        mapObjToTransit,
      );
      if (registrationsData.result == 1) {
        addRegObj.taskReg.regId = registrationsData.data;
        addRegObj.taskReg.regCreatedBy =
          registrationsData.resourceLastModifiedDate;
        addRegObj.taskReg.regUpdatedBy = null;
        dispatch(addRegistration({ rowDetail: addRegObj }));
      }

      return registrationsData;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const editRegistrationService = (editRegObj, empId) => {
  return async (dispatch) => {
    try {
      const userContext = store.getState().userContext;
      let requestParamObj = {
        'db/id': {
          value: editRegObj.taskReg.regId,
          isKeyword: false,
        },
        'registration/owner': { value: userContext.userId, isKeyword: false },
        'registration/description': {
          value: editRegObj.taskReg.regDesc,
          isKeyword: false,
        },
        'registration/task': { value: editRegObj.taskId, isKeyword: false },
        'registration/status': {
          value: 'registration.status/'.concat(editRegObj.taskReg.regStatus),
          isKeyword: true,
        },
        'registration/start': {
          value: new Date(Date.parse(editRegObj.regDate)),
          isKeyword: false,
        },
        'registration/start-offset': {
          value: new Date(editRegObj.regDate).getTimezoneOffset(),
          isKeyword: false,
        },
        'registration/quantity': {
          value: Number(editRegObj.taskReg.regDuration),
          isKeyword: false,
        },
        employment: {
          value: empId,
          isKeyword: false,
        },
      };

      let mapObjToTransit = createTransitData(requestParamObj);
      let registrationsData = await httpRequestHandler(
        editRegistrationsInfo,
        mapObjToTransit,
      );

      if (registrationsData.result == 1) {
        editRegObj.taskReg.regUpdatedBy =
          registrationsData.resourceLastModifiedDate;
        dispatch(editRegistration({ rowDetail: editRegObj }));
      }
      return registrationsData;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const deleteRegistrationService = (deleteRegObj) => {
  return async (dispatch) => {
    try {
      const userContext = store.getState().userContext;
      let requestParamObj = {
        'db/id': {
          value: deleteRegObj.taskReg.regId,
          isKeyword: false,
        },
        'registration/owner': { value: userContext.userId, isKeyword: false },
        'registration/description': {
          value: deleteRegObj.taskReg.regDesc,
          isKeyword: false,
        },
        'registration/task': { value: deleteRegObj.taskId, isKeyword: false },
        'registration/status': {
          value: 'registration.status/'.concat(deleteRegObj.taskReg.regStatus),
          isKeyword: true,
        },
        'registration/start': {
          value: new Date(Date.parse(deleteRegObj.regDate)),
          isKeyword: false,
        },
        'registration/start-offset': {
          value: new Date(deleteRegObj.regDate).getTimezoneOffset(),
          isKeyword: false,
        },
        'registration/quantity': {
          value: deleteRegObj.taskReg.regDuration,
          isKeyword: false,
        },
      };

      let mapObjToTransit = createTransitData(requestParamObj);

      let registrationsData = await httpRequestHandler(
        deleteRegistrationsInfo,
        mapObjToTransit,
      );

      if (registrationsData.result == 1) {
        dispatch(deleteRegistration({ rowDetail: deleteRegObj }));
      }

      return registrationsData;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const bulkDeleteRegistrationsService = async (regList) => {
  try {
    const userContext = store.getState().userContext;
    let requestParamList = [];

    regList.forEach((task) => {
      for (const [date, value] of Object.entries(task.taskReg)) {
        value.forEach((reg) => {
          let requestParamObj = {
            'db/id': {
              value: reg.regId,
              isKeyword: false,
            },
            'registration/owner': {
              value: userContext.userId,
              isKeyword: false,
            },
            'registration/description': {
              value: reg.regDesc,
              isKeyword: false,
            },
            'registration/task': { value: task.taskId, isKeyword: false },
            'registration/status': {
              value: 'registration.status/'.concat(reg.regStatus),
              isKeyword: true,
            },
            'registration/start': {
              value: new Date(Date.parse(date)),
              isKeyword: false,
            },
            'registration/start-offset': {
              value: new Date(date).getTimezoneOffset(),
              isKeyword: false,
            },
            'registration/quantity': {
              value: reg.regDuration,
              isKeyword: false,
            },
          };
          let mapObjToTransit = createTransitData(requestParamObj);
          requestParamList.push(JSON.parse(mapObjToTransit));
        });
      }
    });

    let registrationsData = {};

    if (requestParamList.length !== 0) {
      registrationsData = await httpRequestHandler(
        deleteRegistrationsInfo,
        requestParamList,
      );
    } else {
      registrationsData = {
        result: 2,
        errorMessage: 'No registrations to process.',
        data: null,
      };
    }

    return registrationsData;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const submitRegistrations = (registrationObj) => {
  return async (dispatch) => {
    try {
      const userContext = store.getState().userContext;
      let requestParamList = [];
      registrationObj.forEach((task) => {
        for (const [date, value] of Object.entries(task.taskReg)) {
          value.forEach((reg) => {
            let requestParamObj = {
              'db/id': {
                value: reg.regId,
                isKeyword: false,
              },
              'registration/owner': {
                value: userContext.userId,
                isKeyword: false,
              },
              'registration/description': {
                value: reg.regDesc,
                isKeyword: false,
              },
              'registration/task': { value: task.taskId, isKeyword: false },
              'registration/status': {
                value: 'registration.status/'.concat(reg.regStatus),
                isKeyword: true,
              },
              'registration/start': {
                value: new Date(Date.parse(date)),
                isKeyword: false,
              },
              'registration/start-offset': {
                value: new Date(date).getTimezoneOffset(),
                isKeyword: false,
              },
              'registration/quantity': {
                value: reg.regDuration,
                isKeyword: false,
              },
              employment: {
                value: task.empId,
                isKeyword: false,
              },
            };
            let mapObjToTransit = createTransitData(requestParamObj);
            requestParamList.push(JSON.parse(mapObjToTransit));
          });
        }
      });

      let registrationsData = await httpRequestHandler(
        editRegistrationsInfo,
        requestParamList,
      );
      if (registrationsData.result == 1) {
        dispatch(
          bulkEditRegistration({
            registrations: registrationObj,
            regLastModifiedDate: registrationsData.resourceLastModifiedDate,
          }),
        );
      }

      return registrationsData;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

export const addTaskService = async (taskObj) => {
  try {
    let requestParamList = [];
    let requestParamObj = {
      'task/project': { value: taskObj.taskProjectId, isKeyword: false },
      'task/title': { value: taskObj.taskTitle, isKeyword: false },
      employment: {
        value: taskObj.empId,
        isKeyword: false,
      },
    };

    let mapObjToTransit = createTransitData(requestParamObj);
    requestParamList.push(JSON.parse(mapObjToTransit));

    let response = await httpRequestHandler(addTaskInfo, requestParamList);

    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const getTaskService = async (taskObj) => {
  try {
    //get task info
    let response = await httpRequestHandler(getTaskInfo, {
      id: taskObj,
    });

    if (response.result === 1) {
      let mappedResponseDataObj = {
        taskId: 'db/id',
        taskProjectId: 'task/project',
        taskTitle: 'task/title',
        taskCreator: 'meta/created-by',
      };
      let mappedResponseData = getTransitDataCollection(
        response.data,
        mappedResponseDataObj,
      );
      mappedResponseData.taskRegistrations = {};
      mappedResponseData.taskTotalRegTime = 0;

      response.data = mappedResponseData;
    }

    return response;
  } catch (error) {
    throw new Error('Error on Service Request');
  }
};

export const deleteTaskService = (taskObj) => {
  return async (dispatch) => {
    try {
      let requestParamList = [];

      let requestParamObj = {
        'db/id': { value: taskObj.taskId, isKeyword: false },
        'task/project': { value: taskObj.taskProjectId, isKeyword: false },
        'task/title': { value: taskObj.taskTitle, isKeyword: false },
      };

      let mapObjToTransit = createTransitData(requestParamObj);
      requestParamList.push(JSON.parse(mapObjToTransit));
      let taskData = await httpRequestHandler(deleteTaskInfo, requestParamList);

      if (taskData.result == 1 && taskData.data == taskObj.taskId) {
        dispatch(
          deleteProjectTask({
            taskObj: taskObj,
          }),
        );
      }

      return taskData;
    } catch (error) {
      throw new Error('Error on Service Request');
    }
  };
};

//time registration utilities
//sum of registrations
export const getTotalRegistration = (regList) => {
  let sumOfReg = 0;

  regList.forEach((proj) => {
    proj.projectTasks.forEach((task) => {
      for (let [date, value] of Object.entries(task.taskRegistrations)) {
        value.forEach((reg) => {
          sumOfReg += Number(reg.regDuration);
        });
      }
    });
  });
  return sumOfReg;
};

//sum of registrations per date
export const getTotalRegistrationsPerDate = (regList, regDate) => {
  let sumOfReg = 0;

  regList.forEach((proj) => {
    proj.projectTasks.forEach((task) => {
      for (let [date, value] of Object.entries(task.taskRegistrations)) {
        if (regDate === date) {
          value.forEach((reg) => {
            sumOfReg += Number(reg.regDuration);
          });
        }
      }
    });
  });

  return sumOfReg;
};

//sum of registrations per task
export const getTotalRegistrationPerTask = (taskRegList) => {
  let sumOfReg = 0;
  for (let [date, value] of Object.entries(taskRegList)) {
    value.forEach((reg) => {
      sumOfReg += Number(reg.regDuration);
    });
  }

  return sumOfReg;
};

//API Request Parameter
const getRegistrationsInfo = {
  url: 'me/projects',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: 'query-string',
  hasAuth: true,
  'x-registration-unit': 'minutes',
};

const addRegistrationsInfo = {
  url: 'me/registrations',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
  'x-registration-unit': 'minutes',
};

const editRegistrationsInfo = {
  url: 'me/registrations',
  method: 'PUT',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
  'x-registration-unit': 'minutes',
};

const deleteRegistrationsInfo = {
  url: 'me/registrations',
  method: 'DELETE',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
  'x-registration-unit': 'minutes',
};

const addTaskInfo = {
  url: 'me/tasks',
  method: 'POST',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const deleteTaskInfo = {
  url: 'me/tasks',
  method: 'DELETE',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  hasAuth: true,
  contentTypeHeader: 'application/transit+json',
  requestParamType: 'transit-json',
};

const getTaskInfo = {
  url: 'me/tasks',
  method: 'GET',
  transformResponse: [
    function (data) {
      return data;
    },
  ],
  requestParamType: 'query-string',
  contentTypeHeader: 'application/transit+json',
  hasAuth: true,
};

const getOwnerDetails = (value) => {
  var result = null;
  if (value) {
    let mapToObject = {
      id: 'db/id',
      team: 'team/name',
      organization: 'organization/name',
      firstName: 'user/firstname',
      lastName: 'user/lastname',
    };
    let mappedData = getTransitDataCollection(value, mapToObject);
    var name = '';
    if (mappedData.team) name = mappedData.team;
    if (mappedData.organization) name = mappedData.organization;
    if (mappedData.firstName)
      name = `${mappedData.firstName} ${mappedData.lastName}`;
    result = {
      id: mappedData.id,
      name: name,
    };
  }

  return result;
};
