import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classes from './TeamsAndProjects.module.scss';
import {
  leaveTeamService,
  getProjectList,
  getMyTeamList,
  getMeProjectList,
} from '../../../service/orgmanagementService';
import { requestToLeaveSuccessMessage } from '../../../../../utilities/contentMessagesUtils';
import { useOutletContext } from 'react-router-dom';
import TeamContainer from './teamContainer/TeamContainer';
import { useTranslation } from 'react-i18next';

const TeamsAndProjects = () => {
  const { selectedOrg, cbShowSuccessSnackBarFn, cbShowErrorSnackBarFn } =
    useOutletContext();
  const dispatch = useDispatch();
  const meProjectList = useSelector((state) => state.orgContext.projectList);
  const userId = useSelector((state) => state.userContext.userId);
  const userTeamList = useSelector((state) => state.orgContext.myTeams);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedTeam, setClickedTeam] = useState({});
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const [userTeamListState, setUserTeamListState] = useState([]);

  useEffect(() => {
    if (selectedOrg?.emp) {
      load_data(selectedOrg?.emp);
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (userTeamList) {
      setUserTeamListState([
        {
          id: selectedOrg?.id,
          isActive: true,
          label: 'Org-wide',
        },
        ...userTeamList,
      ]);
    }
  }, [userTeamList]);

  const load_data = async (employmentId) => {
    await dispatch(getMyTeamList(employmentId));
    await dispatch(getProjectList(employmentId));
    await dispatch(getMeProjectList(employmentId, false));
  };

  const handleOpenMenuClick = (event, team) => {
    event.stopPropagation();
    setClickedTeam(team);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();

    setAnchorEl(null);
  };

  const handleLeaveTeam = async (event) => {
    const leaveTeamRequestResponse = await leaveTeamService(clickedTeam.id);

    if (leaveTeamRequestResponse.result === 1) {
      cbShowSuccessSnackBarFn(
        t('organization.leave-request-successfully-sent'),
      );
    } else {
      cbShowErrorSnackBarFn(leaveTeamRequestResponse.errorMessage);
    }

    handleClose(event);
  };

  return (
    <Grid
      container
      item
      direction="column"
      className={classes.teams_proj_container}
    >
      <Grid item>
        <Typography className={classes.header}>
          {t('we-leave.my-teams-and-projects')}
        </Typography>
        <Typography className={classes.header_desc}>
          {t('we-leave.view-your-team-and-its-project-details-under')} "
          {selectedOrg?.organizations?.name}"
        </Typography>
      </Grid>
      <Grid item className={`${classes.teams_container} teams_projects`}>
        {userTeamListState
          .filter((team) => team.isActive === true)
          ?.map((team) => {
            return (
              <TeamContainer
                team={team}
                userId={userId}
                cbHandleOpenMenuClick={handleOpenMenuClick}
                meProjectList={meProjectList}
                selectedOrgId={selectedOrg?.id}
              />
            );
          })}
      </Grid>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          className={classes.menu_cell_icon}
          key={1}
          onClick={handleLeaveTeam}
        >
          <CloseIcon className={classes.close_icon} />
          <Typography>{t('organization.leave-team')}</Typography>
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default TeamsAndProjects;
