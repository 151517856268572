import React, { useState, useRef } from 'react';
import classes from './History.module.scss';
import { styled } from '@mui/material/styles';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import ConfirmationDialogBox from '../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import SuccessSnackbar from '../../../../ui/snackbar/successSnackbar/SuccessSnackbar';
import {
  LeaveStatusType,
  DialogActionsType,
} from '../../../../../utilities/constantUtils';
import { sendLeaveRequestService } from '../../service/leaveService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateFormats } from '../../../../../utilities/dateUtils';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { datePickerLanguage } from '../../../../../i18n/i18nUtil';

const DataGridContainer = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    borderBottom: '1px solid #e9e9e9',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover svg': {
    visibility: 'visible !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell:nth-of-type(1)': {
    position: 'relative',
  },
}));

const History = (props) => {
  const { t } = useTranslation();
  const { leaveList } = props;
  const successSnackbarRef = useRef(null);
  const [severity, setSeverity] = useState('info');
  const [startDate, setStartDate] = useState(props.dateFrom);
  const [endDate, setEndDate] = useState(props.dateTo);
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: <></>,
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
    SubmitLabelText: '',
  });

  const columns = [
    {
      field: 'action',
      headerName: t('we-leave.action'),
      width: 80,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <Grid className={classes.request_action}>
              {item.row.status.toLowerCase() !== LeaveStatusType.approved ? (
                <Button
                  className={`${classes.datatable_request_button_action} ${classes.green}`}
                  onClick={() => approveRequest(item)}
                >
                  <CheckIcon />
                </Button>
              ) : (
                <Button
                  className={`${classes.datatable_request_button_action} ${classes.red}`}
                  onClick={() => declineRequest(item)}
                >
                  <CloseIcon />
                </Button>
              )}
            </Grid>
          </React.Fragment>
        );
      },
    },
    {
      field: 'ownerName',
      headerName: t('we-leave.member'),
      width: 200,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <Avatar
              className={classes.member_avatar}
              alt={item.row.ownerName}
              src="./broken-image.jpg"
            />
            <div>{item.row.ownerName}</div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'status',
      headerName: t('we-leave.status'),
      width: 100,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.firstLetter}>{item.row.status}</div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'teamsList',
      headerName: t('we-leave.team'),
      width: 250,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.wrapDiv}>{item.row.teamsList}</div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'requestedDate',
      headerName: t('we-leave.requested-date'),
      width: 200,
    },
    {
      field: 'leaveTypeName',
      headerName: t('we-leave.leave-type'),
      width: 150,
    },
    {
      field: 'reason',
      headerName: t('we-leave.reason'),
      width: 200,
    },
    {
      field: 'updatedByName',
      headerName: t('we-leave.modified-by-on'),
      width: 300,
      renderCell: (item) => {
        return (
          <React.Fragment>
            {item.row?.updatedBy !== undefined && (
              <>
                {item.row?.updatedBy !== null ? (
                  <div>
                    {item?.row?.updatedByName} on {item?.row?.updatedAt}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  const confirmSubmissionHandler = async () => {
    handleCloseDialogBox();
  };

  const responseMessage = (message, response) => {
    if (response.errorMessage === '') {
      handleCloseDialogBox();
      showSuccessSnackBar(message);
      props.getHistoryLeave(startDate, endDate);
    } else if (response.errorMessage.includes('current update')) {
      invalidRequest();
    } else {
      handleCloseDialogBox();
      setSeverity('error');
      // showSuccessSnackBar('Error while processing request.');
      showSuccessSnackBar(response.errorMessage);
    }
  };

  const invalidRequest = () => {
    var value = {
      title: t('we-leave.opps-you-cant-do-any-action-in-this-leave-request'),
      description: t(
        'we-leave.it-seems-that-there-are-current-update-on-this-request-from-your-team-members-side',
      ),
      actionType: DialogActionsType.success,
      type: t('we-leave.ok-got-it'),
    };
    dialogBox(value, null);
  };

  const dialogBox = (value, closeLabelText) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{value.title} </>
        </Typography>
      ),
      dialogContent: (
        <Typography variant="subtitle1" color="text.secondary">
          {value.description}
        </Typography>
      ),
      showActionButton: true,
      closeLabelText: closeLabelText,
      submitLabelText: value.type,
      actionType: value.actionType,
      leaveType: value.leaveType,
    }));
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };

  const showSuccessSnackBar = (message) => {
    successSnackbarRef.current.snackBarContentHandler(message);
  };

  const declineRequest = async (value) => {
    var response = await sendLeaveRequestService(
      value.row,
      LeaveStatusType.declined,
    );
    responseMessage(t('we-leave.leave-declined'), response);
  };

  const approveRequest = async (value) => {
    var response = await sendLeaveRequestService(
      value.row,
      LeaveStatusType.approved,
    );
    responseMessage(t('we-leave.leave-approved'), response);
  };

  const getDate = (value, field) => {
    if (value == null) return;
    if (!isNaN(value)) {
      var date = moment(new Date(value)).format(
        dateFormats.yyyymmdd.toUpperCase(),
      );
      if (moment(date).isValid()) {
        if (field === 'dateFrom') {
          setStartDate(value);
          if (value > endDate) {
            setEndDate(value);
            props.getHistoryLeave(value, value);
          } else {
            setStartDate(value);
            props.getHistoryLeave(value, endDate);
          }
        } else if (field === 'dateTo') {
          if (value < startDate) {
            setEndDate(startDate);
            props.getHistoryLeave(startDate, startDate);
          } else {
            setEndDate(value);
            props.getHistoryLeave(startDate, value);
          }
        }
      }
    }
  };

  const HistoryActions = () => {
    return (
      <GridToolbarContainer>
        <Grid container className={classes.request_action_container}>
          <div className={classes.padR10}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={datePickerLanguage()}
            >
              <DatePicker
                label={t('we-leave.filter-start-date')}
                value={new Date(startDate)}
                onChange={(newValue) => {
                  getDate(newValue, 'dateFrom');
                }}
                renderInput={(params) => <TextField {...params} />}
                className={classes.date_picker}
                format={dateFormats.MMddyyyy}
              />
            </LocalizationProvider>
          </div>
          <div className={classes.padR10}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={datePickerLanguage()}
            >
              <DatePicker
                label={t('we-leave.filter-end-date')}
                value={new Date(endDate)}
                onChange={(newValue) => {
                  getDate(newValue, 'dateTo');
                }}
                renderInput={(params) => <TextField {...params} />}
                className={classes.date_picker}
                format={dateFormats.MMddyyyy}
              />
            </LocalizationProvider>
          </div>
          <div className={classes.padT8}>
            <GridToolbarFilterButton />
          </div>
        </Grid>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={confirmSubmissionHandler}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
      />
      <div className={classes.snackbar_wrapper}>
        <SuccessSnackbar ref={successSnackbarRef} severity={severity} />
      </div>

      <div
        className={classes.filter_tab}
        style={{ height: 400, width: '100%' }}
      >
        <div className={classes.container}>
          <DataGridContainer
            autoHeight={true}
            columns={columns}
            rows={leaveList}
            components={{
              Toolbar: HistoryActions,
            }}
            getRowHeight={() => 'auto'}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 20, 100]}
            sx={{
              '.MuiTablePagination-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
            localeText={{
              toolbarFilters: t('we-leave.advance-filters'),
              MuiTablePagination: {
                labelRowsPerPage: t('common.rows-per-page'),
              },
            }}
          />

          {props.loader && (
            <div
              className={
                classes.box + ' ' + classes.stackTop + ' ' + classes.box2
              }
            >
              <div className={classes.center}>
                <CircularProgress />
                <Typography>{t('we-leave.getting-history-leaves')}</Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default History;
