import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateFormats } from '../../../../../utilities/dateUtils';
import classes from './FilterTimeRegistration.module.scss';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { userRoles } from '../../../../../utilities/roleUtils';

import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { getUserOrgTeamsList } from '../../../../home/service/homeService';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { datePickerLanguage } from '../../../../../i18n/i18nUtil';

const FilterTimeRegistration = ({
  startDate,
  endDate,
  employee,
  project,
  team,
  getDate,
  onSelectEmployee,
  onSelectProjects,
  onSelectTeams,
  projectList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orgList = useSelector((state) => state.userContext.userOrganizations);
  const mappedOrgId = orgList.map((org) => {
    return org.id;
  });

  const userTeamList = useSelector((state) =>
    state.orgContext.teams.filter((team) => team.orgId === mappedOrgId[0]),
  );

  const userProfileId = useSelector((state) => state.userContext.userId);
  const organizations = useSelector(
    (state) => state.userContext.userOrganizations,
  );

  const buildMembers = (memberList) => {
    const employees = memberList.map((item) => {
      return {
        label: item.memberFirstName + ' ' + item.memberLastName,
        value: item.memberId,
      };
    });
    return [
      {
        label: t('we-timesheet.all-employees'),
        value: t('we-timesheet.all-employees'),
      },
      ...employees,
    ];
  };

  useEffect(() => {
    dispatch(getUserOrgTeamsList(userProfileId));
  }, [userProfileId]);

  const overAllActiveMember = useSelector(
    (state) => state.orgContext.activeMemberList,
  );

  const teams = useMemo(() => {
    if (userTeamList.length > 0) {
      let checkAdmin = false;
      organizations.forEach((org) => {
        var isAdmin = org.userAuth.find((auth) => auth === userRoles.orgAdmin);
        if (isAdmin) {
          checkAdmin = true;
        }
      });
      let filterTeams = userTeamList;
      if (!checkAdmin) {
        filterTeams = userTeamList.filter(
          (team) => team.teamManager.userId === userProfileId,
        );
      }
      const teams = filterTeams.map((userTeam) => {
        return {
          label: userTeam.teamName,
          value: userTeam.teamId,
        };
      });
      const allTeamOrgWide = [{ label: t('we-timesheet.all-teams'), value: 0 }];
      orgList.forEach((org) => {
        allTeamOrgWide.push({
          label: `Org-wide(${org?.organizations?.name})`,
          value: org.id,
        });
      });

      return [...allTeamOrgWide, ...teams];
    } else {
      return [];
    }
  }, [userTeamList]);

  const members = useMemo(() => {
    if (team?.value) {
      const isTeamValueAValidId = typeof team.value === 'number';
      const filteredActiveMemberByTeam = isTeamValueAValidId
        ? overAllActiveMember.filter((member) =>
            member.memberTeamEmployment.some((teamObject) => {
              return teamObject.teamId === team.value;
            }),
          )
        : overAllActiveMember;
      const employees = buildMembers(filteredActiveMemberByTeam);
      return employees;
    } else {
      const employees = buildMembers(overAllActiveMember);
      return employees;
    }
  }, [overAllActiveMember, team]);

  const isTwoMonths = (date) => {
    let startOfTwoMonths = moment().subtract(63, 'days').startOf('day');
    return date <= startOfTwoMonths;
  };

  return (
    <div className={classes.reg_filter}>
      <Grid container className={classes.request_action_container}>
        <div className={classes.padR10}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={datePickerLanguage()}
          >
            <DatePicker
              label={t('we-timesheet.filter-start-date')}
              value={new Date(startDate)}
              shouldDisableDate={isTwoMonths}
              onChange={(newValue) => {
                getDate(newValue, 'dateFrom');
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  style: { paddingBottom: '8px' },
                },
                openPickerIcon: {
                  component: InsertInvitationIcon,
                },
              }}
              renderInput={(params) => <TextField {...params} />}
              className={classes.date_picker}
              format={dateFormats.MMddyyyy}
            />
          </LocalizationProvider>
        </div>
        <div className={classes.padR10}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={datePickerLanguage()}
          >
            <DatePicker
              label={t('we-timesheet.filter-end-date')}
              value={new Date(endDate)}
              onChange={(newValue) => {
                getDate(newValue, 'dateTo');
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  style: { paddingBottom: '8px' },
                },
                openPickerIcon: {
                  component: InsertInvitationIcon,
                },
              }}
              renderInput={(params) => <TextField {...params} />}
              className={classes.date_picker}
              format={dateFormats.MMddyyyy}
              minDate={startDate}
            />
          </LocalizationProvider>
        </div>

        <div className={classes.padR10}>
          <Autocomplete
            disablePortal
            id="teams"
            defaultValue={t('we-timesheet.all-teams')}
            options={teams}
            variant="standard"
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('we-timesheet.filter-teams')}
                variant="standard"
              />
            )}
            onChange={onSelectTeams}
          />
        </div>
        <div className={classes.padR10}>
          <Autocomplete
            disablePortal
            id="projects"
            defaultValue={t('we-timesheet.all-projects')}
            value={project}
            options={projectList}
            variant="standard"
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('we-timesheet.filter-projects')}
                variant="standard"
              />
            )}
            onChange={onSelectProjects}
          />
        </div>
        <div className={classes.padR10}>
          <Autocomplete
            disablePortal
            id="employees"
            value={employee}
            defaultValue={t('we-timesheet.all-employees')}
            options={members}
            variant="standard"
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('we-timesheet.filter-employees')}
                variant="standard"
              />
            )}
            onChange={onSelectEmployee}
          />
        </div>
      </Grid>
    </div>
  );
};
export default FilterTimeRegistration;
