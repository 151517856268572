import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import classes from './ConfirmationDialogBox.module.scss';
import { DialogActionsType } from '../../../utilities/constantUtils';
import CircularProgress from '@mui/material/CircularProgress';

import './ConfirmationDialogBox.scss';

const ConfirmationDialogBox = (props) => {
  const [textValue, setTextValue] = useState(null);
  const [isTextBoxError, setIsTextBoxError] = useState(false);
  const handleCloseDialogBox = () => {
    setIsTextBoxError(false);
    props.handleClose();
  };

  useEffect(() => {
    if (isTextBoxError) {
      const hasValidInput = textValue && textValue.length > 0;
      if (hasValidInput) setIsTextBoxError(false);
    }
  }, [isTextBoxError, textValue]);

  const handleAction = () => {
    if (props.isError) {
      if (textValue) {
        if (textValue.length > 0) {
          props.handleAction(textValue);
        } else {
          setIsTextBoxError(true);
        }
      } else {
        setIsTextBoxError(true);
        setTextValue('');
      }
    } else {
      props.handleAction(textValue);
    }
  };

  const checkIsLoading = () => {
    var result = false;
    result = props.isLoading === undefined ? false : props.isLoading;
    return result;
  };

  const onChangeHandler = (value) => {
    if (value) {
      setTextValue(value.target.value);
    }
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={handleCloseDialogBox}
      id={props.id}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '900px',
          },
        },
      }}
    >
      <DialogTitle component={'div'}>{props.title}</DialogTitle>

      <DialogContent>
        <DialogContentText component={'div'}>
          <>
            {props.inpuText && (
              <>
                {props.isError ? (
                  <TextField
                    id="standard-multiline-flexible"
                    label={props.inputTextTitle}
                    fullWidth
                    multiline
                    onChange={onChangeHandler}
                    variant="standard"
                    error={isTextBoxError}
                    helperText={
                      isTextBoxError && props.helperText
                        ? props.helperText
                        : undefined
                    }
                  />
                ) : (
                  <TextField
                    id="standard-multiline-flexible"
                    label={props.inputTextTitle}
                    fullWidth
                    multiline
                    onChange={onChangeHandler}
                    variant="standard"
                  />
                )}
              </>
            )}
            <Typography mt={1}>{props.content}</Typography>
          </>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {props.closeLabelText !== null && (
          <Button
            onClick={handleCloseDialogBox}
            disabled={props.isLoading === undefined ? false : props.isLoading}
          >
            <span className={classes.cancelLabel}>{props.closeLabelText}</span>
          </Button>
        )}
        {props.showActionButton && (
          <>
            {!checkIsLoading() && (
              <Button onClick={handleAction}>
                {props.actionType === DialogActionsType.error ? (
                  <span className={classes.error}>{props.actionLabelText}</span>
                ) : (
                  <span>{props.actionLabelText}</span>
                )}
              </Button>
            )}
          </>
        )}
        {props.isLoading && (
          <Button disabled={true}>
            <div className={classes.flex}>
              <div className="ConfirmationLoader padT2 padR3">
                <CircularProgress className={classes.circular} size="1rem" />
              </div>
              <div>{props.loaderText ? props.loaderText : 'SAVING'}</div>
            </div>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogBox;
