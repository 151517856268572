import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Popover, Typography } from '@mui/material';
import { format, isToday } from 'date-fns';
import TextBox from '../../../../../../../../ui/formcontrols/textBox/TextBox';
import FormButton from '../../../../../../../../ui/formcontrols/formButton/FormButton';
import ConfirmationDialogBox from '../../../../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import CircularProgressLoader from '../../../../../../../../ui/circularProgressLoader/CircularProgressLoader';
import classes from './RegistrationModal.module.scss';
import { dateFormats } from '../../../../../../../../../utilities/dateUtils';
import { LeaveStatusType } from '../../../../../../../../../utilities/constantUtils';
import {
  submitType,
  deleteType,
  dialogContentText,
  deleteContentText,
} from '../utils/configs';
import TimeInput from '../timeInput/TimeInput';
import { useTranslation } from 'react-i18next';

const RegistrationModal = ({
  cellDetail,
  closeMoreDetailsPopUp,
  id,
  openPopOver,
  isCreatingRegistration,
  anchorEl,
  onDurationChangeHandler,
  onBlurHrModal,
  onKeyPressHrModal,
  onDescriptionChangeHandler,
  onBlurDescriptionModal,
  onKeyPressDescriptionModal,
  onDeleteButtonClick,
  onSubmitForApprovalClick,
  disableSubmitButton,
  openConfirmationBox,
  dialogContentTitle,
  popOverhandleCloseDialogBox,
  handleAction,
}) => {
  const { t } = useTranslation();
  const cellDuration = cellDetail?.taskReg?.regDuration;
  const [minutesValue, setMinutesValue] = useState(cellDuration);

  useEffect(() => {
    setMinutesValue(cellDuration ? cellDuration : '');
  }, [cellDuration]);

  useEffect(() => {
    minutesValue && onDurationChangeHandler(minutesValue, cellDuration);
  }, [minutesValue]);

  const noTimeQuantity = !!!minutesValue;

  return (
    <Popover
      id={id}
      open={openPopOver}
      anchorEl={anchorEl}
      onClose={closeMoreDetailsPopUp}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: '325px',
          height:
            cellDetail?.taskReg?.regStatus !== LeaveStatusType.pending
              ? '280px'
              : '280px',
        },
      }}
    >
      <React.Fragment>
        {showStatus(cellDetail, closeMoreDetailsPopUp, t)}
      </React.Fragment>
      <div
        className={`${classes.popover_body} ${
          isCreatingRegistration && classes.loading
        }`}
      >
        <TimeInput
          id="reg-timeDuration"
          withLabel
          className={classes.duration_input_field}
          InputLabelProps={{
            shrink: true,
          }}
          minutesValue={minutesValue}
          setMinutesValue={setMinutesValue}
          onBlur={onBlurHrModal}
          onKeyDown={onKeyPressHrModal}
          isDisabled={
            cellDetail?.taskReg?.regStatus === LeaveStatusType.approved ||
            isCreatingRegistration
          }
          isAutoFocus
        />

        <div className={classes.desc_text_field}>
          <TextBox
            id="reg-description"
            label={t('me.enter-task-description-optional')}
            type="text"
            fullWidth={false}
            value={cellDetail?.taskReg?.regDesc}
            onChange={(e) =>
              onDescriptionChangeHandler(e, cellDetail?.taskReg?.regDesc)
            }
            onBlur={onBlurDescriptionModal}
            onKeyPress={onKeyPressDescriptionModal}
            disabled={
              cellDetail?.taskReg?.regStatus === LeaveStatusType.approved ||
              isCreatingRegistration
            }
          />
        </div>
        {cellDetail?.taskReg?.regStatus !== LeaveStatusType.approved && (
          <div className={classes.save_button}>
            <FormButton
              label={t('common.delete').toUpperCase()}
              color={
                cellDetail?.taskReg?.regStatus === LeaveStatusType.pending
                  ? 'error'
                  : 'secondary'
              }
              variant="outlined"
              labelVariant="buttonsmall"
              onClick={onDeleteButtonClick}
              disabled={noTimeQuantity || isCreatingRegistration}
            />
            <FormButton
              label={t('me.submit-for-approval-c')}
              color="primary"
              variant="contained"
              labelVariant="buttonsmall"
              onClick={onSubmitForApprovalClick}
              disabled={
                disableSubmitButton ||
                isCreatingRegistration ||
                noTimeQuantity ||
                !parseFloat(cellDetail.taskReg.regDuration)
              }
            />
          </div>
        )}
        {isCreatingRegistration && (
          <div className={classes.loading_spinner}>
            <CircularProgressLoader size={40} />
          </div>
        )}
      </div>
      <ConfirmationDialogBox
        open={openConfirmationBox.open}
        title={dialogContentTitle}
        content={getContent(openConfirmationBox.type, t).displayContent}
        handleClose={popOverhandleCloseDialogBox}
        handleAction={handleAction}
        closeLabelText="CANCEL"
        actionLabelText={getContent(openConfirmationBox.type, t).displayLabel}
        showActionButton={true}
      />
    </Popover>
  );
};

export default RegistrationModal;

const getContent = (openConfirmationBoxType, translation) => {
  let displayContent = '';
  let displayLabel = '';

  switch (openConfirmationBoxType) {
    case submitType:
      displayContent = translation(
        'me.this-will-notify-your-manager-approver-to-approve-this-task',
      );
      displayLabel = submitType;
      break;
    case deleteType:
      displayContent = translation(
        'me.this-will-notify-your-manager-approver-to-approve-this-task',
      );
      displayLabel = deleteType;
      break;
    default:
      displayContent = '';
      displayLabel = '';
  }

  return { displayContent: displayContent, displayLabel: displayLabel };
};

const showStatus = (cellDetail, closeMoreDetailsPopUp, t) => {
  var message = <></>;
  var headerStyling;
  const registrationStatus = cellDetail?.taskReg?.regStatus;
  const isTimeRegistrationNew = cellDetail?.taskReg?.regId !== -1;

  switch (registrationStatus) {
    case 'draft':
      if (isTimeRegistrationNew) {
        message = <>{t('me.all-changes-are-saved')}</>;
        headerStyling = classes.draft;
      } else {
        message = (
          <>
            <div>Registering for</div>
            <Typography
              variant="caption"
              color="white"
              fontWeight={'fontWeightLight'}
              className={classes.reg_timestamp}
            >
              {isToday(new Date(cellDetail?.regDate))
                ? 'Today'
                : format(
                    new Date(cellDetail?.regDate),
                    dateFormats.long_date_V2,
                  )}
            </Typography>
          </>
        );
        headerStyling = classes.draft;
      }
      break;
    case 'pending':
      message = <>{t('me.you-submitted-this-for-approval')}</>;
      headerStyling = classes.pending;
      break;
    case 'approved':
      message = <>{t('me.approved')}</>;
      headerStyling = classes.approved;
      break;
    default:
      headerStyling = classes.draft;
  }

  return (
    <div className={`${classes.popover_header} ${headerStyling}`}>
      <IconButton
        aria-label="close form registration"
        className={classes.close_icon}
        onClick={closeMoreDetailsPopUp}
      >
        <CloseIcon />
      </IconButton>

      <div className={classes.popover_text}>
        <Typography variant="caption" color="white">
          {message}
        </Typography>
        {(cellDetail?.taskReg?.regUpdatedBy ||
          cellDetail?.taskReg?.regCreatedBy) && (
          <Typography
            variant="caption"
            color="white"
            fontWeight={'fontWeightLight'}
            className={classes.reg_timestamp}
          >
            {cellDetail?.taskReg?.regUpdatedBy
              ? `${
                  isToday(new Date(cellDetail?.taskReg?.regUpdatedBy))
                    ? 'Today'
                    : format(
                        new Date(cellDetail?.taskReg?.regUpdatedBy),
                        dateFormats.default_v2,
                      )
                } at ${format(
                  new Date(cellDetail?.taskReg?.regUpdatedBy),
                  dateFormats.time,
                )}`
              : `${
                  isToday(new Date(cellDetail?.taskReg?.regCreatedBy))
                    ? 'Today'
                    : format(
                        new Date(cellDetail?.taskReg?.regCreatedBy),
                        dateFormats.default_v2,
                      )
                } at ${format(
                  new Date(cellDetail?.taskReg?.regCreatedBy),
                  dateFormats.time,
                )}`}
          </Typography>
        )}
      </div>
    </div>
  );
};
