import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Button,
  Grid,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TextField,
  FormHelperText,
  Alert,
} from '@mui/material';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HealingIcon from '@mui/icons-material/Healing';
import InfoOutlineIcon from '@mui/icons-material/InfoOutlined';
import classes from './Leave.module.scss';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  sendLeaveRequestService,
  getLeaveCollision,
  editLeaveRequestService,
  deleteLeaveRequestService,
} from '../service/leaveService';

import { setLeaveTotalLength } from '../../../store/leaveContextSlice';

import SuccessSnackbar from '../../ui/snackbar/successSnackbar/SuccessSnackbar';
import ErrorSnackbar from '../../ui/snackbar/errorSnackbar/ErrorSnackbar';
import { util } from '../../../utilities/utilFunc';
import {
  getBusinessDays,
  isDateGreaterThan,
  handleDateFormatFullYearRange,
} from '../../../utilities/dateUtils';
import ConfirmModal from '../../ui/confirmModal/ConfirmModal';
import CustomTooltip from '../../ui/tooltips/Tooltips';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  dateFormats,
  handleDateFormatRangeYear,
  isEqualToCurrentDate,
} from '../../../utilities/dateUtils';
import {
  LeaveType,
  LeaveStatusType,
  LeaveEntitlement,
} from '../../../utilities/constantUtils';
import dayjs from 'dayjs';
import { setCollisionList } from '../../../store/leaveContextSlice';
import { useTranslation } from 'react-i18next';
import { datePickerLanguage } from '../../../i18n/i18nUtil';
import moment from 'moment';

var _ = require('lodash');

const RequestLeave = ({
  type,
  onClickRequestLeave,
  leaveDetails,
  edit,
  leaveTypeId,
  saveHandler,
  onBack,
}) => {
  const { t } = useTranslation();
  const uf = util();
  const leaveTypesList = useSelector((state) => state.leaveContext.leaveTypes);
  const isEmpty = _.isEmpty(leaveDetails) && uf.isNullOrUndefined(leaveTypeId);

  const initialData = () => {
    if (isEmpty) {
      return {
        desc: '',
        typeName: { id: '', type: '' },
        start: new Date(),
        end: new Date(),
        halfDay: '',
        remaining: '',
        entitlement: '',
      };
    } else {
      const typeId = uf.isNullOrUndefined(leaveTypeId)
        ? leaveDetails.typeName.id
        : leaveTypeId;
      const initialType = leaveTypesList.filter((item) => {
        return item.leaveTypeid === typeId;
      });

      return {
        desc: '',
        typeName: { id: leaveTypeId, type: initialType[0].label },
        start: new Date(),
        end: new Date(),
        halfDay: '',
        remaining: initialType[0].remaining,
        entitlement: initialType[0].entitlement,
      };
    }
  };

  const isEdit = leaveDetails ? leaveDetails : initialData();
  const collisionList = useSelector(
    (state) => state.leaveContext.collisionList,
  );
  const leaveTotalLength = useSelector(
    (state) => state.leaveContext.leaveTotalLength,
  );

  const [selectedType, setSelectedType] = useState(initialData().typeName.type);
  const [selectedTypeId, setSelectedTypeId] = useState(
    initialData().typeName.id,
  );

  const [remainingLeaves, setRemainingLeaves] = useState(
    initialData().remaining,
  );
  const [entitlement, setEntitlement] = useState(initialData().entitlement);
  const [isChecked, setChecked] = useState(false);
  const [reason, setReason] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const totalDays = getBusinessDays(startDate, endDate);
  const [typeHasError, setTypeHasError] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openBackButton, setOpenBackButton] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const isInvdalidDate = isDateGreaterThan(startDate, endDate);
  const [isStartDateFormatValid, setIsStartDateFormatValid] = useState(true);
  const [isEndDateFormatValid, setIsEndDateFormatValid] = useState(true);
  const [totalCountLeave, setTotalCountLeave] = useState(null);

  useEffect(() => {
    if (leaveTotalLength.length) {
      setTotalCountLeave(leaveTotalLength[0].length);
    } else {
      setTotalCountLeave(null);
    }
  }, [leaveTotalLength]);

  useEffect(() => {
    return () => {
      dispatch(setLeaveTotalLength({ leaveTotalLength: [] }));
    };
  }, []);

  const onLoadLeaveCollision = (startDateChange, endDateChange) => {
    let leaveRequestParam = {
      desc: reason,
      type: selectedTypeId,
      start: startDateChange,
      end: endDateChange,
      status:
        type === LeaveType.sickness
          ? LeaveStatusType.approved
          : LeaveStatusType.pending,
    };

    dispatch(getLeaveCollision(leaveRequestParam));
  };

  const getCollistion = (startDateChange, endDateChange, selectedTypeId) => {
    let leaveRequestParam = {
      desc: '',
      type: selectedTypeId,
      start: startDateChange,
      end: endDateChange,
      status:
        type === LeaveType.sickness
          ? LeaveStatusType.approved
          : LeaveStatusType.pending,
    };
    dispatch(getLeaveCollision(leaveRequestParam));
  };

  useEffect(() => {
    dispatch(setCollisionList({ collisionList: [] }));
  }, []);

  useEffect(() => {
    const startDateFormat = uf.isNullOrUndefined(isEdit.start)
      ? new Date()
      : new Date(isEdit.start);
    const endDateFormat = uf.isNullOrUndefined(isEdit.end)
      ? new Date()
      : new Date(isEdit.end);
    const start = startDateFormat;
    const end = endDateFormat;
    const desc = uf.isNullOrUndefined(isEdit.desc) ? '' : isEdit.desc;
    const halfDay = isEdit.halfDay === 'half' ? true : false;

    if (selectedTypeId) {
      getCollistion(startDate, endDate, selectedTypeId);
    }

    if (isEdit.typeName) {
      const type = uf.isNullOrUndefined(isEdit.typeName.type)
        ? initialData().typeName.type
        : isEdit.typeName.type;
      const typeId = uf.isNullOrUndefined(isEdit.typeName.id)
        ? leaveTypeId
        : isEdit.typeName.id;

      setSelectedType(type);
      setSelectedTypeId(typeId);

      const typeFilter = typeOfLeave.filter((item) => {
        return item.leaveTypeid === isEdit.typeName.id;
      });

      const mappedRemaining = typeFilter.map((item) => {
        return item.remaining;
      });
      setRemainingLeaves(mappedRemaining[0]);
      setChecked(halfDay);
    }

    setReason(desc);
    setStartDate(start);
    setEndDate(end);
  }, []);

  const successSnackbarRef = useRef(null);
  const errorSnackbarRef = useRef(null);
  const dispatch = useDispatch();
  const isRegisterLeaveBtnDisabled =
    type === LeaveType.sickness
      ? uf.isNullOrUndefined(reason) ||
        isInvdalidDate ||
        !isStartDateFormatValid ||
        !isEndDateFormatValid ||
        collisionList.length !== 0 ||
        !selectedType // Disable if the label is not selected
      : edit
      ? collisionList.length !== 0 || isInvdalidDate
      : uf.isNullOrUndefined(reason) ||
        (remainingLeaves <= 0 && entitlement !== LeaveEntitlement.unlimited) ||
        isInvdalidDate ||
        !isStartDateFormatValid ||
        !isEndDateFormatValid ||
        collisionList.length !== 0 ||
        !selectedType;

  const sickLeaveTypes = leaveTypesList.filter((item) => {
    return item.type === LeaveType.sickness;
  });
  const vacationLeaveTypes = leaveTypesList.filter((item) => {
    return item.type !== LeaveType.sickness;
  });
  const typeOfLeave =
    type === LeaveType.sickness ? sickLeaveTypes : vacationLeaveTypes;

  const handleMenuItemClick = (item) => {
    setSelectedTypeId(item.leaveTypeid);
    setSelectedType(item.label);
    setRemainingLeaves(item.remaining);
    setEntitlement(item.entitlement);
    getCollistion(startDate, endDate, item.leaveTypeid);
  };

  const onSelectTypeBlurHandler = (event) => {
    if (event.target.textContent === '') {
      setTypeHasError(true);
    } else {
      setTypeHasError(false);
    }
  };

  const handleChangeCheckBox = (event) => {
    setChecked(event.target.checked);
    setEndDate(startDate);
    if (!event.target.checked) {
      setTotalCountLeave(null);
    }
  };

  const OnInputChange = (event) => {
    setReason(event.target.value);
  };

  const sendRequestLeave = async (statusType) => {
    let leaveRequestParam = {
      id: leaveDetails.id,
      desc: reason,
      type: selectedTypeId,
      start: moment(startDate).format(dateFormats.moment_MMddyyyy),
      end: moment(endDate).format(dateFormats.moment_MMddyyyy),
      halfDay: isChecked,
      status:
        type === LeaveType.sickness ? LeaveStatusType.approved : statusType,
    };
    const response = edit
      ? await editLeaveRequestService(leaveRequestParam)
      : await sendLeaveRequestService(leaveRequestParam);

    if (response.result === 1) {
      var message = '';
      setOpenConfirmModal(false);
      if (type === LeaveType.sickness) {
        message = t('me-leave.sickness-is-now-reported');
      } else {
        message =
          statusType === LeaveStatusType.pending
            ? edit
              ? t('me-leave.leave-request-updated')
              : t('me-leave.leave-submitted-for-approval')
            : t('me-leave.leave-saved-as-draft');
      }
      setSelectedType('');
      setSelectedTypeId();
      setReason('');
      setChecked(false);
      setStartDate(new Date());
      setEndDate(new Date());
      setRemainingLeaves('');
      onChangeStartDate(new Date());
      onChangeEndDate(new Date());
      onLoadLeaveCollision(new Date(), new Date());
      saveHandler(message);
    } else {
      setOpenConfirmModal(false);
      errorSnackbarRef.current.snackBarContentHandler(response.errorMessage);
    }
  };

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const handleClose = () => setOpenConfirmModal(false);
  const handleCloseModal = () => setOpenDiscardModal(false);
  const handleCloseModalBack = () => setOpenBackButton(false);
  const handleDiscard = () => {
    setOpenDiscardModal(true);
  };

  const onChangeStartDate = async (newStartDateValue) => {
    const formattedDate = new Date(newStartDateValue);
    const isValidDate = !isNaN(formattedDate.getTime());

    if (isValidDate) {
      setIsStartDateFormatValid(true);
      setStartDate(newStartDateValue);
      const startDateGreaterThanEndDate = isDateGreaterThan(
        newStartDateValue,
        endDate,
      );
      if (isChecked || startDateGreaterThanEndDate) {
        setEndDate(newStartDateValue);
        onLoadLeaveCollision(newStartDateValue, newStartDateValue);
      } else {
        onLoadLeaveCollision(newStartDateValue, endDate);
      }
    } else {
      setIsStartDateFormatValid(false);
    }
  };

  const onChangeEndDate = async (newEndDateValue) => {
    const formattedDate = new Date(newEndDateValue);
    const isValidDate = !isNaN(formattedDate.getTime());

    if (isValidDate) {
      setIsEndDateFormatValid(true);
      setEndDate(newEndDateValue);
      onLoadLeaveCollision(startDate, newEndDateValue);
    } else {
      setIsEndDateFormatValid(false);
    }
  };

  const hasChange =
    !isEqualToCurrentDate(startDate) ||
    !isEqualToCurrentDate(endDate) ||
    !uf.isNullOrUndefined(reason) ||
    isChecked;

  const onBackBtn = () => {
    onBack(false);
  };

  const onDiscardBtn = hasChange
    ? handleDiscard
    : onClickRequestLeave(false, type, {}, false);
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const deleteDraftRequest = async (id) => {
    const response = await deleteLeaveRequestService(id);
    if (response.result === 1) {
      setSelectedType('');
      setSelectedTypeId();
      setReason('');
      setChecked(false);
      setStartDate(new Date());
      setEndDate(new Date());
      setRemainingLeaves('');
      onChangeStartDate(new Date());
      onChangeEndDate(new Date());
      onLoadLeaveCollision(new Date(), new Date());
      onBack(true);
    } else {
      successSnackbarRef.current.snackBarContentHandler(response.errorMessage);
    }
    setOpenConfirmModal(false);
  };

  const disabledSubmit = () => {
    if (!isRegisterLeaveBtnDisabled && totalCountLeave > 0) {
      return false;
    }
    return true;
  };
  const getTotalDays = () => {
    if (totalCountLeave !== null && totalCountLeave !== undefined) {
      return totalCountLeave;
    }
    return totalDays;
  };

  return (
    <>
      <Grid container item direction="column">
        <div className={classes.request_leave_container}>
          <Grid className={classes.back_btn}>
            <Button
              color="inherit"
              size="small"
              onClick={onBackBtn}
              className={classes.back_btn}
            >
              <KeyboardBackspaceSharpIcon
                sx={{ fontSize: '17px', margin: '0 5px' }}
              />
              {t('common.back')}
            </Button>
          </Grid>
          <Grid item className={classes.header}>
            <div className={classes.request_leave_header}>
              <Typography className={classes.title}>
                {type === LeaveType.sickness ? (
                  <HealingIcon
                    style={{ fontSize: '30px', verticalAlign: 'top' }}
                  />
                ) : (
                  <EmojiTransportationIcon
                    style={{ fontSize: '30px', verticalAlign: 'top' }}
                  />
                )}{' '}
                {type === LeaveType.sickness
                  ? t('me-leave.report-sickness')
                  : t('me-leave.request-a-leave')}
              </Typography>
              <Typography className={classes.title_desc}>
                {t(
                  'me-leave.input-your-leave-details-and-then-submit-for-approval',
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 500 }}
              className="marginB28"
              error={typeHasError}
            >
              <Grid item className={classes.space_bottom}>
                <TextField
                  id="leave-type-label"
                  select
                  label={
                    type === LeaveType.sickness
                      ? t('me-leave.sickness-type')
                      : t('me-leave.leave-type')
                  }
                  defaultValue={selectedType}
                  value={selectedType}
                  helperText={
                    type === LeaveType.sickness ||
                    uf.isNullOrUndefined(selectedTypeId) === null ||
                    uf.isNullOrUndefined(remainingLeaves) ||
                    (edit && remainingLeaves <= 0)
                      ? null
                      : remainingLeaves <= 0
                      ? t(
                          'me-leave.you-dont-have-any-remaining-leave-entitlement',
                        )
                      : `${t('me-leave.you-still-have')} ${
                          remainingLeaves % 1 === 0
                            ? remainingLeaves.toFixed(0)
                            : remainingLeaves.toFixed(2)
                        } ${t('me-leave.available-days')}`
                  }
                  variant="standard"
                  className={classes.input_reason}
                  onClose={onSelectTypeBlurHandler}
                  FormHelperTextProps={{
                    style: { color: remainingLeaves <= 0 ? 'red' : 'black' },
                  }}
                >
                  {typeOfLeave.map((option) => (
                    <MenuItem
                      key={option.label}
                      value={option.label}
                      onClick={(menuItem) => handleMenuItemClick(option)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {typeHasError && (
                  <FormHelperText>{t('me-leave.invalid-team')}</FormHelperText>
                )}
              </Grid>
              <Grid container className={classes.space_bottom}>
                <Grid item xs={6}>
                  <div>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={datePickerLanguage()}
                    >
                      <DatePicker
                        label={t('me-leave.start-date')}
                        value={startDate}
                        onChange={(newValue) => {
                          onChangeStartDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField onKeyDown={onKeyDown} {...params} />
                        )}
                        className={classes.date_picker}
                        format={dateFormats.MMddyyyy}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={datePickerLanguage()}
                    >
                      <DatePicker
                        label={t('me-leave.end-date')}
                        value={endDate}
                        onChange={(newValue) => {
                          onChangeEndDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField onKeyDown={onKeyDown} {...params} />
                        )}
                        className={classes.date_picker}
                        minDate={dayjs(new Date(startDate))}
                        disabled={isChecked}
                        format={dateFormats.MMddyyyy}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.space_bottom}>
                <Grid item xs={6}>
                  <div>
                    <Typography variant="body2" color="text.secondary">
                      {t('me-leave.half-day-leave')}
                    </Typography>
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={isChecked}
                          onChange={handleChangeCheckBox}
                        />
                      }
                      label={t('me-leave.half-day-leave')}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <Typography variant="body2" color="text.secondary">
                      <div>{t('me-leave.total-days')}</div>
                    </Typography>
                  </div>
                  <div className={classes.total_days}>
                    <Typography variant="body2">
                      <div className={classes.div_block}>
                        {isChecked ? '0.50' : getTotalDays()} days
                      </div>
                      <div className={classes.info_icon}>
                        <CustomTooltip
                          title={
                            <div className={classes.tooltip}>
                              {t('me-leave.total-working-days')} <br />
                              {t('me-leave.excluding-weekends-and-holiday')}
                            </div>
                          }
                          placement={'top'}
                          fontSize={11}
                          color={'rgba(0, 0, 0, 0.87)'}
                          child={<InfoOutlineIcon sx={{ fontSize: 14 }} />}
                        />
                      </div>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item className={classes.space_bottom}>
                <TextField
                  id="leave-reason"
                  label={t('me-leave.reason')}
                  multiline
                  maxRows={3}
                  variant="standard"
                  errorText={t('me-leave.please-input-a-reason')}
                  className={classes.input_reason}
                  onChange={OnInputChange}
                  inputProps={{
                    autocomplete: 'off',
                  }}
                  value={reason}
                />
              </Grid>
              {collisionList.length === 0 ? null : (
                <div>
                  <Alert
                    icon={
                      <ErrorOutlineIcon
                        fontSize="inherit"
                        className={classes.alert_icon}
                      />
                    }
                    severity="error"
                    className={classes.alert_style}
                  >
                    <div className={classes.collision_desc}>
                      {t(
                        'me-leave.uh-oh-your-selected-dates-have-existing-leave-requests',
                      )}
                    </div>
                    {collisionList.map((item) => {
                      return (
                        <>
                          <Grid
                            container
                            spacing={2}
                            className={classes.desc_container}
                          >
                            <Grid item xs={2}>
                              <div>
                                <div className={classes.desc_icon}>
                                  {item.type === LeaveType.leave ? (
                                    <EmojiTransportationIcon />
                                  ) : (
                                    <HealingIcon />
                                  )}
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={10}>
                              <div>
                                <div className={classes.collision_desc}>
                                  {handleDateFormatFullYearRange(
                                    item.start,
                                    item.end,
                                  )}
                                </div>
                                <div className={classes.desc}>
                                  {item.description}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  </Alert>
                </div>
              )}

              <Grid container spacing={2} className={classes.space_bottom}>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<CheckCircleOutlineIcon color="white" />}
                    onClick={handleOpenConfirmModal}
                    disabled={disabledSubmit()}
                  >
                    {type === LeaveType.sickness
                      ? t('me-leave.report-sickness')
                      : t('me-leave.submit-for-approval')}
                  </Button>
                  {type !== LeaveType.sickness && (
                    <div className={classes.secondary_button}>
                      <Button
                        variant="outlined"
                        onClick={() => sendRequestLeave(LeaveStatusType.draft)}
                        disabled={isRegisterLeaveBtnDisabled}
                      >
                        {t('me-leave.save-as-draft')}
                      </Button>
                    </div>
                  )}
                  <div className={classes.secondary_button}>
                    <Button variant="outlined" onClick={onDiscardBtn}>
                      {t('me-leave.discard').toUpperCase()}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <ConfirmModal
            title={t('me-leave.would-you-like-to-discard-this-leave')}
            description={t(
              'me-leave.this-will-remove-all-your-unsaved-changes',
            )}
            openConfirmModal={openDiscardModal}
            setOpenConfirmModal={handleCloseModal}
            submitConfirmation={
              leaveDetails.status === LeaveStatusType.draft
                ? () => deleteDraftRequest(leaveDetails.id)
                : onClickRequestLeave(false, type, {}, false)
            }
            width={490}
            height={200}
            submitLabel={'Discard'}
          />
          <ConfirmModal
            title={t('me-leave.would-you-like-to-discard-this-leave')}
            description={t(
              'me-leave.this-will-remove-all-your-unsaved-changes',
            )}
            openConfirmModal={openBackButton}
            setOpenConfirmModal={handleCloseModalBack}
            submitConfirmation={onClickRequestLeave(false, type, {}, false)}
            width={490}
            height={200}
            submitLabel={t('me-leave-discard')}
          />
          <ConfirmModal
            title={
              type === LeaveType.sickness
                ? t('me-leave.would-you-like-to-report-your-sickness')
                : `${t(
                    'me-leave.would-you-like-to-submit-your',
                  )} ${handleDateFormatRangeYear(startDate, endDate)} ${t(
                    'me-leave.vacation-leave-for-approval',
                  )} ?`
            }
            description={
              type === LeaveType.sickness
                ? t(
                    'me-leave.your-sickness-will-be-reported-and-will-be-automatically-approved',
                  )
                : t(
                    'me-leave.this-will-notify-your-manager-approver-to-approve-your-leave',
                  )
            }
            openConfirmModal={openConfirmModal}
            setOpenConfirmModal={handleClose}
            submitConfirmation={() => sendRequestLeave(LeaveStatusType.pending)}
            width={700}
            height={type === LeaveType.sickness ? 200 : 250}
            submitLabel={
              type === LeaveType.sickness
                ? t('me-leave.report-sickness')
                : t('me-leave.submit')
            }
          />
        </div>
        <div className={`${classes.snackbar_wrapper} `}>
          <SuccessSnackbar ref={successSnackbarRef} />
          <ErrorSnackbar ref={errorSnackbarRef} />
        </div>
      </Grid>
    </>
  );
};

export default RequestLeave;
