import React from 'react';
import {
  Accordion,
  AccordionDetails,
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classes from './TeamContainer.module.scss';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ProjectList from './projectList/ProjectList';

import { featureUpdateMessage } from '../../../../../../utilities/contentMessagesUtils';
import { orgTeamsAndProjectFeatureOn } from '../../../../../../utilities/featureToggleUtils';
import FeatureStatusNotification from '../../../../../ui/featureStatusNotification/FeatureStatusNotification';
import { projectStatusName } from '../../../../../../utilities/constantUtils';
import { useTranslation } from 'react-i18next';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreRoundedIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiPaper-root-MuiAccordion-root': {},
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    alignItems: 'center',
    margin: '10px',
  },
  '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
    borderBottom: '1px solid $gray-light4 !important',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '10px',
    minHeight: 'unset',
  },
}));

const TabStyled = styled(Tabs)(({ theme }) => ({
  '& .Mui-selected': {
    color: '#00B8D4 !important',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#00B8D4 !important',
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="orgtabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.org_tab_panel}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const TeamProjectList = (meProjectList, selectedOrgId, id, tab) => {
  let listOfProjects = [];
  if (meProjectList?.length > 0) {
    let teamList = [];
    let orgList = [];
    let orgListNoStatus = [];
    if (tab === 1) {
      if (selectedOrgId !== id) {
        teamList = meProjectList.filter(
          (proj) =>
            proj.ownerDetails.id === id &&
            proj.status === projectStatusName.active,
        );
        listOfProjects = teamList;
      } else {
        orgList = meProjectList.filter(
          (proj) =>
            proj.ownerDetails.id === selectedOrgId &&
            proj.status === projectStatusName.active,
        );
        orgListNoStatus = meProjectList.filter(
          (proj) =>
            proj.ownerDetails.id === selectedOrgId && proj.status === undefined,
        );
        listOfProjects = orgList.concat(orgListNoStatus);
      }
    } else {
      if (selectedOrgId !== id) {
        teamList = meProjectList.filter(
          (proj) =>
            proj.ownerDetails.id === id &&
            proj.status !== projectStatusName.active,
        );
        listOfProjects = teamList;
      } else {
        orgList = meProjectList.filter(
          (proj) =>
            proj.ownerDetails.id === selectedOrgId &&
            proj.status !== projectStatusName.active &&
            proj.status !== undefined,
        );
        listOfProjects = orgList;
      }
    }
  }

  return <ProjectList list={listOfProjects} />;
};

const TeamContainer = ({
  team,
  userId,
  cbHandleOpenMenuClick,
  meProjectList,
  selectedOrgId,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const isManager = team?.manager?.id === userId;
  const hasManager = team?.manager;
  const managerString = isManager
    ? t('organization.managed-by-you')
    : `${t('organization.managed-by')} ${team?.manager?.fName} ${
        team?.manager?.lName
      }`;

  return (
    <React.Fragment className="class" key={team.id}>
      <Accordion
        key={team.id}
        className={classes.accordion_container}
        elevation={1}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordion_header}
        >
          <Typography
            variant="subtitle1"
            className={classes.accordion_header_org}
          >
            {team.label}
          </Typography>

          {hasManager && (
            <>
              <Typography className={classes.accordion_role}>
                <div>
                  <span>{t('organization.employee')}</span>
                  {isManager && <span>{t('organization.manager')}</span>}
                </div>
              </Typography>
              <Typography className={classes.accordion_manager}>
                {managerString}
              </Typography>

              <IconButton
                onClick={(e) => {
                  cbHandleOpenMenuClick(e, team);
                }}
                className={classes.menu_icon}
              >
                <MoreVertIcon />
              </IconButton>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordion_details}>
          {/* FEATURE TOGGLE CODE CLEANUP - remove toggle */}
          {!orgTeamsAndProjectFeatureOn && (
            <FeatureStatusNotification message={featureUpdateMessage} />
          )}
          <Box className={classes.approveLeave_tab_box} sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabStyled
                aria-label="basic tabs example"
                value={activeTab}
                className={classes.approveLeave_tab_name}
                onChange={handleChange}
                columnseparator="false"
              >
                <Tab
                  label={
                    <div>
                      <span className="capitalize">
                        {t('organization.on')}-
                      </span>
                      <span className="tolowercase">
                        {t('organization.going')}
                      </span>
                    </div>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  label={
                    <div className="capitalize">
                      {t('organization.inactive')}
                    </div>
                  }
                  {...a11yProps(1)}
                />
              </TabStyled>
            </Box>
            <TabPanel value={activeTab} index={0} className="leaveTable">
              {TeamProjectList(meProjectList, selectedOrgId, team?.id, 1)}
            </TabPanel>
            <TabPanel value={activeTab} index={1} className="leaveTable">
              {TeamProjectList(meProjectList, selectedOrgId, team?.id, 2)}
            </TabPanel>
          </Box>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default TeamContainer;
