import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import classes from './CreateOrgProjects.module.scss';
import { Button, Box, Tabs, Tab } from '@mui/material';
import InfoTab from './InfoTab/InfoTab';
import MemberList from './list/MemberList';
import { useDispatch } from 'react-redux';
import {
  getUsersApproverList,
  createProject,
  getListOfActiveMembers,
  updateALLProject,
  getListofMembersPerTeam,
} from '../../../../service/orgmanagementService';
import { useSelector } from 'react-redux';
import ConfirmationDialogBox from '../../../../../ui/confirmationDialogBox/ConfirmationDialogBox';
import { dateFormats } from '../../../../../../utilities/dateUtils';

import { DialogActionsType } from '../../../../../../utilities/constantUtils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import { userRoles } from '../../../../../../utilities/roleUtils';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getTeamList,
  getProjectList,
} from '../../../../service/orgmanagementService';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CreateOrgProjects = () => {
  const { t } = useTranslation();
  const statusText = {
    status1: t(
      'organization.team-members-of-this-project-can-register-their-task-and-working-time',
    ),
    status2: t('organization.no-one-can-register-time-for-this-project'),
  };
  const {
    selectedOrg,
    cbShowSuccessSnackBar,
    setSeverity,
    isEdit,
    selectedItem,
  } = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const teamList = useSelector((state) => state.orgContext.teams);
  const usersList = useSelector((state) => state.orgContext.usersList);
  const memberList = useSelector((state) => state.orgContext.activeMemberList);
  const projectList = useSelector((state) => state.orgContext.projectListAll);

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [usersApprover, setUsersApprover] = useState([]);
  const [approver, setApprover] = useState(null);
  const [status, setStatus] = useState('project.status/active');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [projectTitle, setProjectTitle] = useState('');
  const [confirmationBoxInfo, setconfirmationBoxInfo] = useState({
    open: false,
    dialogContentTitle: <></>,
    dialogContent: t('organization.would-you-like-to-save-this-new-project'),
    selectedTasks: [],
    showActionButton: true,
    closeLabelText: t('common.cancel'),
    submitLabelText: t('common.save'),
  });
  const ORGWIDE_ID = 'orgWide';
  const orgTextEveryOne = t(
    'organization.everyone-in-the-org-can-see-this-project',
  );
  const [orgWideText, setOrgWideText] = useState('');
  const [teamName, setTeamName] = useState('Org-wide');
  const [onChangeUpdate, setOnChangeUpdate] = useState(false);
  const membersPerTeam = useSelector(
    (state) => state.orgContext.membersPerTeam,
  );

  const navigateBack = () => navigate(-1);
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('id');

  useEffect(() => {
    if (
      paramValue &&
      projectList.length === 0 &&
      selectedOrg &&
      !selectedItem
    ) {
      dispatch(getProjectList(selectedOrg.emp));
      dispatch(getTeamList(selectedOrg.emp));
    }
  }, [selectedOrg?.emp]);

  useEffect(() => {
    if (
      paramValue &&
      projectList.length > 0 &&
      teamList.length > 0 &&
      !selectedItem
    ) {
      const proj = projectList.find(
        (proj) => proj.id.toString() === paramValue,
      );
      assignProjValue(proj);
    }
  }, [projectList, teamList]);

  useEffect(() => {
    selectedOrg?.emp && dispatch(getUsersApproverList(selectedOrg?.emp));
  }, [selectedOrg?.emp]);

  useEffect(() => {
    if (usersList.length > 0) loadSelectedData();
  }, [usersList, teamList]);

  useEffect(() => {
    if (memberList.length > 0) {
      setMemberListState(memberList);
    }
  }, [memberList]);

  useEffect(() => {
    if (membersPerTeam) {
      setMemberListState(membersPerTeam);
    }
  }, [membersPerTeam]);

  const [memberListState, setMemberListState] = useState([]);
  const loadSelectedData = () => {
    if (selectedItem !== null && isEdit) {
      assignProjValue(selectedItem);
    } else {
      setUsersApprover(usersList);
    }
  };

  const assignProjValue = (item) => {
    setProjectTitle(item?.name);
    if (item?.status) {
      setStatus('project.status/' + item?.status);
      loadTestStatus('project.status/' + item?.status);
    } else {
      setStatus(null);
      loadTestStatus(null);
    }

    setApprover(item?.approver ? item?.approver?.id?.toString() : null);
    setEndDate(item?.end ? item?.end : null);
    setStartDate(item?.start ? item?.start : null);
    var teams = teamList.find((f) => f.id === item?.ownerDetails.id);
    if (teams) {
      loadTeams(teams.label);
    } else {
      if (selectedOrg?.id === item?.ownerDetails?.id) {
        setSelectedTeam(isTeamListSchemaForPicker ? teamList[0] : null);
        setUsersApprover(usersList);
        setOrgWideText(orgTextEveryOne);
        setMemberListState(memberList);
      } else {
        setSelectedTeam(null);
        setMemberListState(memberList);
      }
    }
  };

  const onChangeTeams = async (e, item) => {
    loadTeams(item);
    setOnChangeUpdate(true);
  };
  const listofMembersPerTeam = async (item) => {
    await dispatch(getListofMembersPerTeam(item.id));
  };

  const isTeamListSchemaForPicker =
    teamList?.length !== 0 && teamList?.[0]?.label !== undefined;

  useEffect(() => {
    if (!isTeamListSchemaForPicker && selectedOrg?.emp) {
      dispatch(getTeamList(selectedOrg?.emp));
    }
  }, [selectedOrg?.emp]);

  const loadTeams = async (item) => {
    var value = teamList.find((f) => f.label === item);
    var newData = [];
    setOrgWideText('');
    if (value.id === ORGWIDE_ID) {
      setTeamName('Org-wide');
      newData = usersList;
      setOrgWideText(orgTextEveryOne);
      selectedOrg?.id && (await getListOfMembers(selectedOrg?.id));
    } else {
      setTeamName(value.label);
      usersList.forEach((f) => {
        var result = false;
        f.teams.forEach((t) => {
          if (t.id === value.id) {
            result = true;
            return;
          }
        });
        if (result) {
          newData.push(f);
        } else if (f.roles.find((f) => f === userRoles.orgAdmin)) {
          newData.push(f);
        }
      });
      await listofMembersPerTeam(value);
    }

    setUsersApprover(newData);
    setSelectedTeam(isTeamListSchemaForPicker ? value : null);
  };

  const [memberListLoader, setMemberListLoader] = useState(false);
  const getListOfMembers = async (id) => {
    setMemberListLoader(true);
    selectedOrg?.emp &&
      (await dispatch(getListOfActiveMembers(selectedOrg?.emp)));
    setMemberListLoader(false);
  };

  useEffect(() => {
    selectedOrg?.id && getListOfMembers(selectedOrg.id);
  }, [selectedOrg?.id]);

  const onChangeApprover = (e, value) => {
    setOnChangeUpdate(true);
    setApprover(value.props.value.toString());
  };

  const [statusLabel, setStatusLabel] = useState(statusText.status1);

  const onChangeStatus = (e, value) => {
    loadTestStatus(value.props.value);
    setStatus(value.props.value);
    setOnChangeUpdate(true);
  };

  const loadTestStatus = (value) => {
    if (value) {
      setStatusLabel(statusText.status2);
      if (value === 'project.status/active') {
        setStatusLabel(statusText.status1);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onProjectTitle = (value) => {
    if (value) {
      setProjectTitle(value.target.value);
      setOnChangeUpdate(true);
    }
  };

  const onChangeDate = (value, field) => {
    switch (field) {
      case 0:
        setStartDate(new Date(value));
        break;
      default:
        setEndDate(value ? new Date(value) : null);
        break;
    }
    setOnChangeUpdate(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async () => {
    if (activeDialog === 0) {
      if (isEdit) {
        putData();
      } else {
        postData();
      }
    } else {
      navigateBack();
    }
  };

  const postData = async () => {
    var approverId = usersApprover.find(
      (f) => f.id.toString() === approver.toString(),
    ).id;

    var item = {
      title: projectTitle,
      teams:
        selectedTeam.id === ORGWIDE_ID ? selectedOrg?.id : selectedTeam?.id,
      approver: approverId,
      startDate: startDate,
      endDate: endDate,
      status: status,
      employment: selectedOrg?.emp,
    };
    setIsLoading(true);

    var response = await createProject(item);
    if (response.status === 200) {
      setTimeout(() => {
        setIsLoading(false);
        handleCloseDialogBox();
        navigateBack();
        setSeverity('info');
        cbShowSuccessSnackBar(t('organization.new-project-created'));
      }, 1000);
    } else {
      setIsLoading(false);
      setSeverity('error');
      cbShowSuccessSnackBar(t('organization.error-while-processing-request'));
    }
  };

  const putData = async () => {
    var approverId = usersApprover.find(
      (f) => f.id.toString() === approver.toString(),
    ).id;

    var item = {
      id: selectedItem?.id,
      title: projectTitle,
      teams:
        selectedTeam.id === ORGWIDE_ID ? selectedOrg?.id : selectedTeam?.id,
      approver: approverId,
      startDate: startDate,
      endDate: endDate,
      status: status,
      employment: selectedOrg?.emp,
    };
    setIsLoading(true);
    var response = await updateALLProject(item);
    if (response.status === 200) {
      setTimeout(() => {
        setIsLoading(false);
        handleCloseDialogBox();
        navigateBack();
        setSeverity('info');
        cbShowSuccessSnackBar(t('organization.project-updated'));
      }, 1000);
    } else {
      setIsLoading(false);
      setSeverity('error');
      cbShowSuccessSnackBar(t('organization.error-while-processing-request'));
    }
  };

  const handleCloseDialogBox = () => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: false,
      dialogContentTitle: <></>,
      dialogContent: <></>,
      selectedTasks: [],
      showActionButton: true,
      closeLabelText: t('common.cancel'),
      leaveType: null,
    }));
  };

  const dialogBox = (value, closeLabelText) => {
    setconfirmationBoxInfo((prevState) => ({
      ...prevState,
      open: true,
      dialogContentTitle: (
        <Typography
          variant="h6"
          fontWeight="fontWeightSemiBold"
          color="text.primary"
        >
          <>{value.title} </>
        </Typography>
      ),
      dialogContent: (
        <Typography variant="subtitle1" color="text.secondary">
          {value.description}
        </Typography>
      ),
      showActionButton: true,
      closeLabelText: closeLabelText,
      submitLabelText: value.type,
      actionType: value.actionType,
      leaveType: value.leaveType,
    }));
  };

  const confirmSubmissionHandler = async () => {
    setActiveDialog(0);
    setIsLoading(false);
    var value = {};
    if (isEdit) {
      value = {
        title: t(
          'organization.would-you-like-to-save-your-changes-on-this-project',
        ),
        description: t(
          'organization.the-changes-will-be-effective-immediately-after-saving',
        ),
        type: t('common.save'),
        actionType: DialogActionsType.success,
        leaveType: t('organization.approve-all').toUpperCase(),
      };
    } else {
      value = {
        title: t('organization.would-you-like-to-save-this-new-project'),
        description: t(
          'organization.this-project-will-be-available-to-all-its-members-when-tagged-as-on-going-otherwise-in-will-remain-as-hidden',
        ),
        type: t('common.save'),
        actionType: DialogActionsType.success,
        leaveType: t('organization.approve-all').toUpperCase(),
      };
    }

    dialogBox(value, t('common.cancel'));
  };

  const [activeDialog, setActiveDialog] = useState(false);

  const discardHandler = async () => {
    setActiveDialog(1);
    if (onChangeUpdate) {
      setIsLoading(false);
      var value = {
        title: t('organization.oops-you-have-unsaved-changes'),
        description: t(
          'organization.discarding-these-changes-will-be-removed-permanently',
        ),
        type: t('organization.discard'),
        actionType: DialogActionsType.error,
        leaveType: t('organization.approve-all').toUpperCase(),
      };
      dialogBox(value, t('common.close'));
    } else {
      navigateBack();
    }
  };

  const [disabledBtn, setDisabledBtn] = useState(true);

  useEffect(() => {
    if (projectTitle && approver && selectedTeam && status && startDate) {
      if (onChangeUpdate) {
        if (isEdit) {
          var id =
            selectedTeam?.id === ORGWIDE_ID
              ? selectedOrg?.id
              : selectedTeam?.id;
          var eDate = selectedItem?.end ? selectedItem?.end : null;
          if (
            selectedItem?.name !== projectTitle ||
            selectedItem?.approver?.id.toString() !== approver ||
            `project.status/${selectedItem?.status}` !== status ||
            moment(selectedItem?.start).format(dateFormats.default) !==
              moment(startDate).format(dateFormats.default) ||
            moment(eDate).format(dateFormats.default) !==
              moment(endDate).format(dateFormats.default) ||
            selectedItem?.ownerDetails?.id !== id
          ) {
            setDisabledBtn(false);
          } else {
            setDisabledBtn(true);
          }
        } else {
          setDisabledBtn(false);
        }
      }
    } else {
      setDisabledBtn(true);
    }
  }, [projectTitle, approver, selectedTeam, status, startDate, endDate]);

  return (
    <>
      <ConfirmationDialogBox
        open={confirmationBoxInfo.open}
        title={confirmationBoxInfo.dialogContentTitle}
        content={confirmationBoxInfo.dialogContent}
        handleClose={handleCloseDialogBox}
        handleAction={onSubmit}
        actionType={confirmationBoxInfo.actionType}
        closeLabelText={confirmationBoxInfo.closeLabelText}
        actionLabelText={confirmationBoxInfo.submitLabelText}
        showActionButton={confirmationBoxInfo.showActionButton}
        isLoading={isLoading}
      />
      <div className={classes.backButton}>
        {' '}
        <Button
          color="secondary"
          size="medium"
          className={`capitalize ${classes.btnBack}`}
          onClick={() => discardHandler()}
          startIcon={<ArrowBackIcon />}
        >
          {t('common.back')}
        </Button>
      </div>
      <div container className={classes.invite_card}>
        <div className={classes.header_con}>
          <div className={classes.flexContainer}>
            <div>
              <Typography className={classes.name}>
                {isEdit
                  ? `${t('organization.manage-project')} ${selectedItem?.name}`
                  : t('organization.create-a-new-project')}
              </Typography>
              <Typography className={classes.desc}>
                {isEdit
                  ? t('organization.update-details-of-this-project')
                  : t(
                      'organization.you-can-add-the-information-of-a-new-project-here',
                    )}
              </Typography>
            </div>
            <div className={classes.invite_button}>
              <div className="padR10">
                <Button
                  color="secondary"
                  size="medium"
                  className={classes.button}
                  onClick={() => discardHandler()}
                >
                  {t('organization.discard')}
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={confirmSubmissionHandler}
                  disabled={disabledBtn}
                >
                  {isEdit ? t('common.save-changes') : t('common.save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={`${classes.tabs} PanelCustom`}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab key="2" label="Info" className={classes.tab} />
              <Tab key="3" label="Members" className={classes.tab} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <>
              <InfoTab
                teamList={isTeamListSchemaForPicker ? teamList : []}
                usersList={usersList}
                selectedTeam={selectedTeam}
                usersApprover={usersApprover}
                onChangeTeams={onChangeTeams}
                onChangeStatus={onChangeStatus}
                onChangeApprover={onChangeApprover}
                startDate={startDate}
                endDate={endDate}
                onChangeDate={onChangeDate}
                projectTitle={projectTitle}
                approver={approver}
                status={status}
                onProjectTitle={onProjectTitle}
                orgWideText={orgWideText}
                statusLabel={statusLabel}
                selectedItem={selectedItem}
                isEdit={isEdit}
              />
            </>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MemberList
              list={memberListState}
              loader={memberListLoader}
              teamName={teamName}
            />
          </CustomTabPanel>
        </div>
        <div className={classes.manage_details_wrapper}>
          {isEdit ? (
            <>
              <div className={classes.manage_details}>
                <div className={classes.flex}>
                  <div className={classes.manage_sub}>
                    <Typography className={classes.sub_title}>
                      {t('organization.modified-by')}
                    </Typography>
                    <Typography className={classes.sub_title}>
                      {selectedItem?.updatedat && (
                        <>
                          {selectedItem?.updatedby?.name}{' '}
                          {moment(selectedItem.updatedat).format('DD-MM-YYYY')}
                        </>
                      )}
                    </Typography>
                  </div>
                  <div className={classes.manage_sub}>
                    <Typography className={classes.sub_title}>
                      {t('organization.created-by')}
                    </Typography>
                    <Typography className={classes.sub_title}>
                      {selectedItem?.createdat && (
                        <>
                          {selectedItem?.createdby?.name}{' '}
                          {moment(selectedItem?.createdat).format('DD-MM-YYYY')}
                        </>
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CreateOrgProjects;
