import React, { useEffect, useState } from 'react';
import classes from './MemberList.module.scss';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import {
  Grid,
  CircularProgress,
  Button,
  FormControl,
  Autocomplete,
  TextField,
  IconButton,
} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from 'react-i18next';

const DataGridContainer = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    borderBottom: '1px solid #e9e9e9',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover svg': {
    visibility: 'visible !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell:nth-of-type(1)': {
    position: 'relative',
  },
}));

const MemberList = (props) => {
  const { t } = useTranslation();
  const [listState, setListState] = useState([]);
  useEffect(() => {
    setListState(props.list);
  }, [props.list]);

  const columns = [
    {
      field: 'name',
      headerName: t('organization.name'),
      minWidth: 150,
      flex: 1,
      renderCell: (user) => {
        return (
          <React.Fragment>
            {user.row.name ? (
              <div className={`${classes.flex} ${classes.avatarPad}`}>
                <div>
                  <Avatar
                    className={classes.member_avatar}
                    alt={user.row.name}
                    src="./broken-image.jpg"
                  />
                </div>
                <div className="padT2">{user.row.name}</div>
              </div>
            ) : (
              <FormControl variant="standard" className={classes.fullwidth}>
                <Autocomplete
                  id="disable-close-on-select"
                  className={classes.fieldWidth}
                  value={props.selectedEmployee}
                  clearOnEscape
                  onChange={(e, value) => props.onChangeEmployee(e, value)}
                  options={props.usersList.map((user) => user.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('organization.select-name')}
                      variant="standard"
                    />
                  )}
                />
              </FormControl>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'membership',
      headerName: t('organization.membership'),
      minWidth: 150,
      flex: 1,
      renderCell: (user) => {
        return (
          <React.Fragment>
            {user.row.name ? (
              <div className={`${classes.flex} ${classes.fullwidth}`}>
                <div className={`${classes.pad} ${classes.letter} `}>
                  {user.row.memberEmploymentType}
                </div>
                <div className={classes.padRight}>
                  <IconButton
                    className={'padR10'}
                    onClick={() => props.onDeleteHandler(user.row)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className={classes.padRight}>
                <IconButton
                  className={'padR10'}
                  onClick={props.onAddEmployeeHandler}
                >
                  <CheckCircleIcon color="primary" />
                </IconButton>
                <IconButton
                  className={'padR10'}
                  onClick={props.onRemoveHandler}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  const Actions = () => {
    return (
      <GridToolbarContainer className={classes.toolBarContainer}>
        <Grid container>
          <div className={classes.pad5}>
            <GridToolbarFilterButton />
          </div>
          <div className={`${classes.btnRight} btnPrimaryDatagrid`}>
            <Button
              size="medium"
              className={classes.btnColor}
              onClick={props.onCreateMembersHandler}
              startIcon={<AddIcon />}
            >
              {t('organization.add-member').toUpperCase()}
            </Button>
          </div>
        </Grid>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Typography variant="subtitle1" className={classes.subtitle1}>
        {t('organization.add-members')}
      </Typography>
      <Typography variant="subtitle2" className={`padB10 ${classes.subtitle2}`}>
        {t('organization.add-existing-members-to-your-team')}
      </Typography>

      <div className={`${classes.container} leaveTable`}>
        <div
          className={classes.filter_tab}
          style={{ height: 400, width: '100%' }}
        >
          <DataGridContainer
            getRowId={(emp) => emp.id}
            autoHeight={true}
            columns={columns}
            rows={listState}
            getRowHeight={() => 'auto'}
            components={{
              Toolbar: Actions,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 20, 100]}
            sx={{
              '.MuiTablePagination-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
            localeText={{
              toolbarFilters: t('organization.filters'),
              MuiTablePagination: {
                labelRowsPerPage: t('common.rows-per-page'),
              },
            }}
          />

          {props.loader && (
            <div
              className={
                classes.box + ' ' + classes.stackTop + ' ' + classes.box2
              }
            >
              <div className={classes.center}>
                <CircularProgress />
                <Typography>
                  {t('organization.getting-member-list')}{' '}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MemberList;
